<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="login_box">
      <div class="login_box1">
        <!-- <p>站村云站</p> -->
        <p>轻量级云书签管理系统</p>
      </div>
      <div class="login_box2">
        <div class="hah">
          <!-- <h2>登录</h2> -->
        </div>

          <!-- 登录 -->
          <div class="haf">
            <el-form :model="loginForm" :rules="rulesForm" ref="loginForm">
              <el-form-item class="input_one" prop="email">
                <el-input v-model="loginForm.email" placeholder="邮箱"></el-input>
              </el-form-item>
              <el-form-item class="btn_password" prop="password">
                <el-input v-model="loginForm.password" placeholder="密码" show-password></el-input>
              </el-form-item>
              <!-- <el-form-item class="btn_password">
                <el-button type="text">忘记密码？</el-button>
              </el-form-item> -->
              <el-form-item class="btn_login">
                <el-button type="primary" @click="loginFn()">登录</el-button>
              </el-form-item>
              <div class="btn_number">
                <p  class="btn_number_txt">没有站村账号？</p>
                <el-link type="primary" @click="$router.push('/reguser')">注册账号</el-link>
                <!-- <p class="btn_number_txt">体验账号账号: test@qq.com 密码:111111</p> -->

              </div>
            </el-form>

          </div>

        <div class="box_10">
          <div class="box_9">Copyright © {{ new Date().getFullYear() }}  站存存  </div>
          <el-link class="box_7" href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">豫公网安备41140002000709号 &nbsp;</el-link>
          <el-link class="box_8" href="https://beian.miit.gov.cn" target="_blank">   豫ICP备2023023255号</el-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import { loginAPI } from '@/api'
// import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      loginForm:
      {
        email: '',
        password: ''
      },
      rulesForm:
      {
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 15,
            message: '长度最少6个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // ...mapMutations(['updateToken']),
    loginFn () {
      // (1).检查表单时是否校验通过
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          // (2).校验通过,发送ajax
          const { data: res } = await loginAPI(this.loginForm)
          // console.log(res)

          if (res.status !== 0) {
            this.$message(res.message)
          }
          // (3).处理数据
          if (res.status === 0) {
            // 存储token到vuex
            // this.$store.commit('updateToken', res.token)
            // 存储用户信息到vuex
            // this.updateToken(res.token)
            this.$store.commit('updateToken', {

              token: res.token,
              userId: res.userId,
              username: res.username

            })
            // 跳转登录页
            this.$router.push('/home')

            // 弹出信息框
            // this.$message.success(res.message)
          } else {
            // this.$message.error(res.message)
            return false
          }
        }
      })
    }

    

  }

}

</script>

<style scoped>
.login_box {
  display: flex;
  height: 100vh;
}

.login_box1 {

  width: 36%;
  background-color: #F2CED8;
  background-image: url("../assets/sign-in.jpeg");
  background-size: contain;
  background-repeat: no-repeat, repeat;
  background-position: 0% 100%;

}

.login_box1 p {
  color: #fff9fa;
  font-size: 36px;

  padding-top: 80px;
  display: flex;
  justify-content: center;
}

.login_box2 {

  width: 64%;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.hah {

  height: 100px;
  width: 350px;
  padding-top: 194px;
}

.hah h2 {
  font-size: 24px;

  /* font-weight: 900; */
  color: #1d1d1f;
}

.haf {
  width: 350px;
  height: 248px;
  padding-bottom: 194px;

}

.haf el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input_one {
  margin-block-end: 24px;
}

.btn_password {
  margin-block-end: 64px;
}

 .btn_login {
  font-weight: 500;
  font-size: 16px;
}

.btn_login {
  margin-block-end: 2px;
}

.btn_login .el-button {
  width: 350px;
}

.btn_number {
  display: flex;
  flex-wrap: nowrap;

}
.btn_number_txt {
  font-size: 14px;
  color: #666;
}

.box_10 {
  display: flex;
  /* font-size: 12px;
  color: #999; */
}
.box_9 {
  font-size: 12px;
  color: #999;
  /* margin-top: 300px; */
  margin-right: 10px;
}
.box_8 {
  font-size: 12px;
}

.box_7 {
  font-size: 12px;
}

.el-link.el-link--default {
    color: #999;
}
</style>
