<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="bgimg">

        <div class="box-0">
            <div class="box-0-text">
                <span>站村云站 欢迎你的加入</span> <br>
                <span>共建和谐多彩家园</span>

            </div>
            <div class="box-0-two">
                <span>别看村子不咋大呀 有山有水有规划 邻里乡亲挺和睦 可爱站友更合群</span>
            </div>

        </div>

        <div class="box">
            <div class="box-1">
                <el-image src="./designma.png"></el-image>

            </div>
            <div class="box-2">
                <el-image src="./codema.png"></el-image>

            </div>
            <div class="box-3">
                <el-image src="./productma.png"></el-image>

            </div>
        </div>

    </div>
</template>

<script>
export default {
  setup () {

  }
}
</script>

<style scoped>

.bgimg{

    background-image: url('../assets/welimg.svg');
    background-repeat:no-repeat;
    background-position: 580px 130px;
}

.box-0 {
    padding-top: 200px;
    width: 600px;
}

.box-0-text{
    font-size: 40px;
    font-weight:bold;
    color: #333333;
}

.box-0-two {
    padding-top: 14px;
    color: #999;
    font-weight: lighter;
}

 .box {

    display: flex;
    justify-content: flex-start;
    padding: 60px 0px 0 0px;

 }

 .box-1 {
    height: 260px;
    width: 120px;

    margin-right: 60px;
 }

 .box-2 {
    height: 260px;
    width: 120px;

    margin-right: 60px;
 }

 .box-3 {
    height: 260px;
    width: 120px;

 }

</style>
