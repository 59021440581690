import request from '@/utils/request'
import store from '../store'

import qs from 'qs'


// 注册
// export const registerAPI = ({ username, useremail, password }) => {
//   return request({
//     url: '/api/reguser',
//     method: 'POST',

//     data: {
//       // axios传参params,data
//       // params的对象参数名和值，axios源码会把参数和值，拼接成url?后面给后台（query查询字符串）
//       // data的对象参数和值，axios源码会把参数和值，拼接在请求体里（body参数）
//       // username: 'username',
//       // useremail: 'useremail',
//       // password: 'password'
//       username,
//       useremail,
//       password
//     }
//   })
// }





export const sendCodeAPI = (email) => {
  return request.post('/api/sendVerificationCode', qs.stringify({ email }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

// // 注册
// export function registerAPI (index) {
//   return request.post('/api/reguser', index)
// }

export const registerAPI = ({ email, code, password, username }) => {
  return request({
    url: '/api/reguser',
    method: 'POST',

    data: {
      username,
      password,
      email,
      code
    }
  })
}

// 登录
export const loginAPI = ({ email, password }) => {
  return request({
    url: '/api/login',
    method: 'POST',

    data: {
      email,
      password
    }
  })
}

// 获取用户信息
export const getUserInfoAPI = () => {
  return request({
    url: '/my/userinfo',
    headers: {
      Authorization: store.state.token
    }
  })
}

/**
 * 更新-用户基本资料
 * @param {*} param0 { id: 用户id, email: 用户邮箱, nickname: 用户昵称, user_pic: 用户头像地址, username: 用户名 }
 * @returns Promise对象
 */
export const updateUserInfoAPI = ({ id, email, password, username }) => {
  return request({
    url: '/my/userinfo',
    method: 'POST',
    data: {
      id,
      email,
      password,
      username
    }
  })
}

/**
 * 获取-网站分类
 * @returns Promise对象
 */
export const getCategoryListAPI = (userId) => {
  return request({
    url: '/my/website/getcategory/' + userId,
    headers: {
      Authorization: store.state.token
    }

  })
}

/**
 * 增加-网站分类
 * @param {*} param0 { cate_name: 文章分类名字, cate_alias: 文章分类别名 }
 * @returns Promise对象
 */
export const addCategoryAPI = ({ userId, name }) => {
  return request({
    url: `/my/website/addcategory/${userId}`,
    method: 'POST',
    headers: {
      Authorization: store.state.token
    },
    data: {
      name

    }
  })
}

/**
 * 更新-网站分类
 * @param {*} param0 { id: 文章分类id, cate_name: 文章分类名字, cate_alias: 文章分类别名 }
 * @returns Promise对象
 */
export const updateCategoryAPI = ({ id, name }) => {
  return request({
    url: '/my/website/updatecategory',
    method: 'POST',
    headers: {
      Authorization: store.state.token
    },
    data: {
      id,
      name

    }
  })
}

/**
 * 删除-分类
 * @param {*} id 要删除的-分类id
 * @returns Promise对象
 */
export const delCategoryAPI = (id) => {
  return request({
    url: `/my/website/deletecategory/${id}`,
    method: 'DELETE',
    headers: {
      Authorization: store.state.token
    }
    // params: {
    //   id
    // }
  })
}

// 获取banner
export const getBannerAPI = () => {
  return request({
    url: '/my/banner/getbanner/',
    headers: {
      Authorization: store.state.token
    }
  })
}

// 获取用户布局
export const getUserLayoutAPI = (userId) => {
  return request({
    method: 'GET',
    url: '/my/userlayout/layout/' + userId,
    headers: {
      Authorization: store.state.token
    }
  })
}

// 保存修改后的用户布局
export const saveUserLayoutAPI = (userId, layoutData) => {
  return request({
    method: 'POST', // 确保使用 POST 方法
    url: '/my/userlayout/layout',
    headers: {
      Authorization: store.state.token
    },
    data: {
      userId: userId,
      layoutData: layoutData
    }
  });
}

// 获取站村推荐的网站
export const getRecommendAPI = (userId) => {
  return request({
    url: '/my/recommend/getrecommend/' + userId,
    headers: {
      Authorization: store.state.token
    }
  })
}

// 编辑站村推荐的网站
export const editRecommendAPI = (formData) => {
  return request({
    url: '/my/recommend/updaterecommend',
    method: 'POST',
    headers: {
      Authorization: store.state.token
    },
    data: formData
    // data: {
    //   id,
    //   title,
    //   content,
    //   url,
    //   logoImg

    // }
  })
}


// 拖拽推荐的网站
export const saveRecommendLayoutAPI = (userId, updates) => {
  return request({
    method: 'POST',
    url: `/my/recommend/updateSortOrder/${userId}`, // 将 userId 作为 URL 参数
    headers: {
      Authorization: store.state.token
    },
    data: updates // 传递排序更新数据
  });
};



// 拖拽的网站
export const saveLayoutAPI = (updates) => {
  return request({
    method: 'POST',
    url: '/my/websiteall/updateSortOrder', // 将 categoryId 作为 URL 参数
    headers: {
      Authorization: store.state.token
    },
    data: updates // 传递排序更新数据
  });
};

/**
 * 获取-网站
 * @returns Promise对象
 */
export const getWebsiteListAPI = (categoryId) => {
  return request({
    url: `/my/websiteall/list/${categoryId}`,
    headers: {
      Authorization: store.state.token
    }

  })
}

/**
 * 获取-网站
 * @returns Promise对象
 */
export const getWebsiteAPI = (id) => {
  return request({
    url: '/my/websiteall/' + id,
    headers: {
      Authorization: store.state.token
    },
    params: { id }

  })
}

/**
 * 增加-网站
 * @param {*} param0 { cate_name: 文章分类名字, cate_alias: 文章分类别名 }
 * @returns Promise对象
 */
export const addWebsiteAPI = ({ formData, categoryId }) => {
  return request({
    url: `/my/websiteall/add/${categoryId}`,
    
    method: 'POST',
    headers: {
      Authorization: store.state.token,
      // 'Content-Type': 'multipart/form-data'
    },
    data: formData
  })
}

/**
 * 删除-网站
 * @param {*} id 要删除的-网站id
 * @returns Promise对象
 */
export const delWebsiteAPI = (id) => {
  return request({
    url: '/my/websiteall/delete/' + id,
    method: 'DELETE',
    headers: {
      Authorization: store.state.token
    },
    params: {
      id
    }
  })
}

// 编辑网站
export const editWebsiteAPI = (formData) => {
  return request({
    url: '/my/websiteall/edit',
    method: 'POST',
    headers: {
      Authorization: store.state.token,
      'Content-Type': 'multipart/form-data'
    },
    data: formData
    // data: {
    //   id,
    //   title,
    //   content,
    //   url,
    //   logoImg

    // }

  })
}

// 根据id获取对应的网站 数据
export const getWebsiteIdAPI = (id) => {
  return request({
    url: `/my/websiteall/${this.editCard.id}`,
    headers: {
      Authorization: store.state.token
    },
    params: { id }

  })
}

// 根据网址获取对应的网站数据
export const getWebsiteDataAPI = (url) => {
  return request({
    url: '/my/websiteall/getWebsiteData',
    headers: {
      Authorization: store.state.token
    },
    params: { url }

  })
}
