import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import VueScrollTo from 'vue-scrollto'

import axios from '../src/utils/request' //导入封装后的 axios 实例
import config from '../src/utils/config' // 引入 config 文件

import './assets/css/global.css'
import './assets/theme/index.css'

import store from './store'
// import { prototype } from 'core-js/core/dict'

// const axios = require('axios')
// axios.defaults.baseURL = 'http://127.0.0.1:3000'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.$backurl = config.baseURL

// 生产环境
// Vue.prototype.$backurl = 'http://106.15.76.203:8989/'
// 开发环境
// Vue.prototype.$backurl = 'http://127.0.0.1:3000/'


Vue.use(VueScrollTo)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
