<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="mainbox_one">
      <div class="mainbox_one_title">村之花</div>
      <el-row :gutter="90" class="list-box">
        <VueDraggable ref="el" v-model="cardsDatas" @end="updateSortOrder">
          <el-col :span="4" v-for="(cardsData, index) in cardsDatas" :key="index" :index="cardsData.id" class="el_row"
            :data-swapy-slot="cardsData.id">
            <div>
              <el-link :href="cardsData.url" :underline="false" target="_blank">
                <el-card class="box_card" shadow="hover" :body-style="{ padding: '0px' }">
                  <div class="card_header">
                    <el-link :underline="false" icon="el-icon-edit" @click.prevent="editCardsData(cardsData)"></el-link>
                  </div>
                  <el-image class="webimg" :src="cardsData.logoImg"></el-image>
                </el-card>
              </el-link>

              <div class="card_name">
                <el-tooltip class="item" effect="dark" :content="cardsData.content" placement="bottom-start">
                  <el-link target="_blank" :underline="false" :href="cardsData.url">
                    <span>{{ cardsData.title }}</span>
                  </el-link>
                </el-tooltip>
              </div>
            </div>
          </el-col>
        </VueDraggable>
      </el-row>
    </div>

    <!-- 修改弹窗 -->
    <el-dialog title="" :visible.sync="dialogFormVisible" width="83.333333%" top="0px" :modal="false">
      <div class="dialog_box">
        <p>修改</p>
        <el-form :model="editCards" ref="editRef" label-width="158px" label-position="left" :rules="rulesCardRef"
          hide-required-asterisk>
          <el-form-item label="网站地址" prop="url">
            <el-input v-model="editCards.url" @focus="showButton = true"></el-input><el-button type="text"
              v-if="showButton" @click="fetchData" :loading="isLoading">解析网址</el-button>
          </el-form-item>
          <el-form-item label="网站名称" prop="title">
            <el-input v-model="editCards.title"></el-input>
          </el-form-item>
          <el-form-item label="网站描述" prop="content">
            <el-input type="textarea" v-model="editCards.content"></el-input>
          </el-form-item>
          <el-form-item label="网站图标" prop="logoImg">
            <el-card class="box_card" shadow="never" :body-style="{ padding: '0px' }">
              <img :src="editCards.logoImg" alt="" class="cover-img" ref="imgRef" />

              <input type="file" style="display: none" accept="image/*" ref="iptFileRef" @change="onEditChange" />
            </el-card>

            <el-button type="text" @click="chooseImgFn">+ 选择图片</el-button>
          </el-form-item>
        </el-form>
        <div class="dialog_box_btn">
          <el-button type="primary" @click="saveEditCardData">保存</el-button>
          <el-button @click="cancelEdit">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRecommendAPI,
  editRecommendAPI,
  getWebsiteDataAPI,
  saveRecommendLayoutAPI,
} from "@/api";

import { VueDraggable } from "vue-draggable-plus";

export default {
  components: {
    VueDraggable,
  },

  data() {
    return {
      //   dialogFormVisible: true,
      isLoading: false,

      editCards: {
        // 修改卡片数据
        id: "",
        url: "",
        title: "",
        content: "",
        logoImgUrl: "",
        logoImg: "",
      },
      editCardsId: "",
      cardsDatasValue: 0,
      cardsDatas: [],
      layoutList: [],
      // cardsDatas: [
      //   // 卡片数据
      //   {
      //     id: '1',
      //     url: 'https://www.zcool.com.cn/',
      //     logoImg: 'https://favicon.cccyun.cc/https://www.zcool.com.cn/',
      //     title: '站酷',
      //     content: '站酷ZCOOL,中国设计师互动平台'
      //   },
      //   {
      //     id: '2',
      //     url: 'https://www.ui.cn/',
      //     logoImg: 'https://favicon.cccyun.cc/https://www.ui.cn/',
      //     title: 'UI中国',
      //     content: 'UI中国用户体验平台'
      //   },
      //   {
      //     id: '3',
      //     url: 'https://dribbble.com/',
      //     logoImg: 'https://favicon.cccyun.cc/https://dribbble.com/',
      //     title: 'Dribbble',
      //     content: '全球UI设计师作品秀社区'
      //   },
      //   {
      //     id: '4',
      //     url: 'https://www.uisdc.com/',
      //     logoImg: './img/ico-img/youshe.ico',
      //     title: '优设',
      //     content:
      //       '设计师交流学习平台 - 看设计文章，学软件教程，找灵感素材，尽在优设网！'
      //   },
      //   {
      //     id: '5',
      //     url: 'https://www.figma.com/community/',
      //     src: 'https://static.figma.com/app/icon/1/favicon.svg',
      //     title: 'Figma',
      //     content: 'figma官方资源社区'
      //   },
      //   {
      //     id: '6',
      //     url: 'https://www.baidu.com/',
      //     logoImg: 'https://favicon.cccyun.cc/https://www.baidu.com/',
      //     title: '百度',
      //     content: '众里寻她千百度'
      //   }
      // ],

      //   editbuttonStatus: false,
      dialogFormVisible: false, // 弹窗是否显示

      showButton: false, // 解析按钮是否显示

      rulesCardRef: {
        url: [
          { required: true, message: "请输入网址", trigger: "blur" },
          { type: "url", message: "请输入正确的网址", trigger: "blur" },
        ],
        title: [
          { validator: this.validLength, trigger: "blur" },
          // { required: true, message: '请输入网站名称', trigger: 'blur' },
          { min: 1, max: 24, message: "长度1--8个字符", trigger: "blur" },
        ],
        content: [
          // { required: true, message: '请输入网站描述', trigger: 'blur' },
          { max: 50, message: "长度最多50个字符", trigger: "blur" },
        ],
        // logoImg: [

        //   { required: true, message: '请上传网站logo', trigger: 'blur' }

        // ]
      },
    };
  },

  computed: {
    username() {
      return this.$store.state.username;
    },
    userId() {
      return this.$store.state.userId;
    },
  },

  created() {
    // 获取村花
    this.initCateListFn();
  },

  methods: {
    // 校验字符长度的方法
    validLength(rule, value, callback) {
      function getStrLength(str) {
        return str.replace(/[^x00-xff]/g, "xxx").length;
      }
      if (!value) {
        callback(new Error("输入不能为空"));
      } else if (getStrLength(value) < 1) {
        callback(new Error("不能少于 1 个字符"));
      } else if (getStrLength(value) > 24) {
        callback(new Error("不能超过 24 个字符"));
      } else {
        callback();
      }
    },

    // 获取村花3.0
    async initCateListFn() {
      try {
        const res = await getRecommendAPI(this.userId);

        if (res.data && res.data.data) {
          this.cardsDatas = res.data.data.map((card) => {
            const serverAddress = this.$backurl;
            let logoImg = card.logoImg;

            // 检查 logoImg 是否已经是一个完整的 URL
            if (!/^https?:\/\//i.test(logoImg)) {
              // 如果不是，就拼接 serverAddress
              logoImg = `${serverAddress.replace(/\/$/, "")}/${logoImg.replace(
                /^\//,
                ""
              )}`;
            }

            return {
              ...card,
              logoImg,
            };
          });

          // console.log("Cards data:", this.cardsDatas); // 打印数据以调试
        } else {
          console.error("Error: Unexpected API response", res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    // 输入网址获取该网站标题简介和logo数据
    async fetchData() {
      try {
        this.isLoading = true;
        const url = this.editCards.url;
        const res = await getWebsiteDataAPI(url);

        // 只更新特定的字段
        this.editCards.title = res.data.title;
        this.editCards.content = res.data.content;
        this.editCards.logoImg = res.data.logoImg;
        console.log(this.editCards);
      } catch (error) {
        console.error(error);
        // 如果发生错误，你可以在这里处理，例如显示一个错误消息
        this.$message.error("获取网站数据失败 请自己添加");
      } finally {
        this.isLoading = false;
      }
    },

    // 显示修改的数据
    editCardsData(cardsData, index) {
      this.editCards.id = cardsData.id;
      this.editCards.url = cardsData.url;
      this.editCards.title = cardsData.title;
      this.editCards.content = cardsData.content;
      this.editCards.logoImg = cardsData.logoImg;
      this.dialogFormVisible = true;
      this.editCardsId = index;
      // console.log(this.editCards)
    },

    chooseImgFn() {
      this.$refs.iptFileRef.click();
    },

    // 封面选择改变的事件
    // 监听文件选择框的 change 事件
    onEditChange(e) {
      // 获取到用户选择的封面
      const files = e.target.files;
      // console.log(files)
      // 检查文件大小，如果超过100kb，则显示警告并返回
      if (files[0].size > 102400) {
        // alert('logo太大了,不能超过100kb! 你可以加入站村的微信群向站友寻求帮助')
        this.$message.error("图片大小超过100kb!");
        return;
      }
      // 用户选择了封面
      this.editCards.logoImgUrl = files[0];
      const url = URL.createObjectURL(files[0]);
      this.$refs.imgRef.setAttribute("src", url);

      this.$refs.editRef.validateField("logoImg");
    },

    // 保存修改的数据

    saveEditCardData() {
      this.$refs.editRef.validate(async (valid) => {
        if (!valid) return;
        // console.log(this.editCards)
        const formData = new FormData();
        formData.append("id", this.editCards.id);
        formData.append("title", this.editCards.title);
        formData.append("content", this.editCards.content);
        formData.append("url", this.editCards.url);

        // 判断logoImgUrl是文件还是链接
        if (this.editCards.logoImgUrl instanceof File) {
          // 如果是文件，添加到表单
          formData.append("logoImg", this.editCards.logoImgUrl);
        } else if (typeof this.editCards.logoImg === "string") {
          // 如果是链接，添加到表单
          formData.append("logoImg", this.editCards.logoImg);
        }
        const { data: res } = await editRecommendAPI(formData);
        // console.log(res);
        // this.$message.success(res.message);
        // 重新请求列表数据
        this.initCateListFn();
        // this.editCards.id = this.editCardsId
      });
      this.showButton = false;
      this.dialogFormVisible = false;
    },

    cancelEdit() {
      this.showButton = false;
      this.dialogFormVisible = false;
      this.editCards = {};
    },

    //拖拽卡片

    async updateSortOrder() {
      const updates = this.cardsDatas.map((item, index) => ({
        id: item.id,
        sort_order: index + 1,
      }));

      // console.log(updates); // 打印更新数据以确认格式

      const userId = this.userId; // 假设 this.userId 存储当前用户的 ID

      try {
        await saveRecommendLayoutAPI(userId, updates); // 调用 API
        // this.$message.success('排序更新成功！'); // 提示用户
      } catch (error) {
        this.$message.error("更新排序失败！"); // 错误处理
        console.error("更新失败:", error); // 打印错误以便调试
      }
    },

    // // 获取村花1.0
    // async initCateListFn () {
    //   const res = await getRecommendAPI(this.userId)
    //   this.cardsDatas = res.data.data.map(card => {
    //     const serverAddress = this.$backurl
    //     const imagePath = card.logoImg
    //     const logoImg = serverAddress + imagePath
    //     return {
    //       ...card,
    //       logoImg
    //     }
    //   })

    //   // console.log(res)
    // },

    // 获取村花2.0
    // async initCateListFn () {
    //   const res = await getRecommendAPI(this.userId)
    //   this.cardsDatas = res.data.data.map(card => {
    //     const serverAddress = this.$backurl
    //     const imagePath = card.logoImg
    //     let logoImg = imagePath
    //     // 检查 imagePath 是否已经是一个完整的 URL
    //     if (!/^https?:\/\//i.test(imagePath)) {
    //       // 如果不是，就把它当作文件路径来处理
    //       logoImg = serverAddress + imagePath

    //     }
    //     return {
    //       ...card,
    //       logoImg
    //     }
    //   })
    //   // console.log(res)
    // },
  },
};
</script>

<style scoped>
.mainbox_one {
  height: auto;
  padding-top: 32px;
}

.mainbox_one_title {
  width: 400px;
  height: 33px;
  padding-block-end: 18px;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1d1d1f;
  line-height: 33px;
}

/* .grid-content {
  margin-top: 0px;
  height: 120px;

  padding-block-end: 24px;
}

.bg-purple-dark {
  background: #ffffff;
} */

.box_card {
  height: 90px;
  width: 90px;
  border-radius: 12px;
  border: 1px solid #eeeeee;
  background-color: rgb(255, 255, 255);
}

.el_row {
  padding: 0 45px 24px 45px;
}

.card_header {
  float: right;
  padding: 4px 4px;
  opacity: 0;
  /* background-color: aqua; */
}

.el-card:hover .card_header {
  opacity: 1;
}

.el-icon-edit {
  color: #666;
}

.webimg {
  width: 32px;
  height: 32px;
  display: block;
  margin-left: 29px;
  margin-right: 29px;
  padding: 29px 0 29px 0;
}

.card_name {
  text-align: center;
  width: 90px;
  height: 20px;
  font-size: 14px;
  /* font-family: PingFangSC-Regular, PingFang SC; */
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin-top: 8px;
  margin-right: 0;
}

.cover-img {
  width: 32px;
  height: 32px;
  display: block;
  margin-left: 29px;
  margin-right: 29px;
  padding: 29px 0 29px 0;
  /* object-fit: cover; */
}
</style>
