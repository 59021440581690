<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="login_box">
      <div class="login_box1">
        <p>轻量级云书签管理系统</p>
      </div>
      <div class="login_box2">
        <div class="hah">
          <!-- <p>站村云站</p> -->
        </div>

        <!-- 注册账号 -->
        <div class="haf">
          <el-form :model="reguserForm" :rules="rulesRef" ref="reguserForm">

            <el-form-item class="input_one" prop="username">
              <el-input v-model="reguserForm.username" placeholder="用户名"></el-input>
            </el-form-item>

            <el-form-item class="input_two" prop="password">
              <el-input v-model="reguserForm.password" placeholder="密码" show-password></el-input>
            </el-form-item>
            
            <el-form-item class="input_email" prop="email">
              <el-input v-model="reguserForm.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item class="input_code" prop="code">
              <el-input v-model="reguserForm.code" placeholder="">
                <template #suffix>
                  <el-button type="text" @click="sendCode" :disabled="isDisabled">
                    {{ countdown > 0 ? `已发送 ${countdown}秒` : '获取验证码' }}
                  </el-button>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item class="btn_login">
              <el-button type="primary" @click="registerForm()">注册</el-button>

            </el-form-item>
            <div class="btn_1">
              <p class="btn_1_txt">已有站村账号？</p>
              <el-link type="primary" @click="$router.push('/login')">登录</el-link>
            </div>

          </el-form>

        </div>

        <div class="box_10">
          <div class="box_9">Copyright © {{ new Date().getFullYear() }} 站存存 </div>
          <el-link class="box_7" href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank">豫公网安备41140002000709号
            &nbsp;</el-link>
          <el-link class="box_8" href="https://beian.miit.gov.cn" target="_blank"> 豫ICP备2023023255号</el-link>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import { sendCodeAPI, registerAPI } from '@/api'

export default {

  data () {
    return {

      reguserForm: {
        username: '',
        password: '',
        email: '',
        code: ''
      },

      rulesRef: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度最少6个字符', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
        ]
      },
      message: '',
      countdown: 0, // 倒计时秒数
      isDisabled: false // 按钮禁用状态
    }
  },
  methods: {

    // registerForm () {
    //   this.$refs.reguserForm.validate(async valid => {
    //     if (valid) {
    //       // 通过校验
    //       console.log(this.reguserForm)
    //       // 1.调用接口
    //       const { data: res } = await registerAPI(this.reguserForm)
    //       //   console.log(res)
    //       // 2.注册失败，提示用户
    //       if (res.status > 0) {
    //         this.$message(res.message)
    //       }
    //       // 3.注册成功，提示用户
    //       if (res.status === 0) {
    //         console.log(res)
    //         this.$message.success(res.message)
    //         this.$router.push('/login')
    //       }
    //     } else {
    //       return false // 阻止默认提交行为（表单下面红色提示会自动出现——）
    //     }
    //   })
    // }

    // // 获取验证码
    

    async sendCode() {
      // 校验 email 是否填写
      if (!this.reguserForm.email) {
        this.$message.error('请填写邮箱地址');
        return;
      }

      try {
        const response = await sendCodeAPI(this.reguserForm.email);
        const { data } = response;
        if (data.code === 0) {
          this.$message.success('验证码已发送');
          // 成功后启动倒计时
        this.startCountdown();
        } else {
          this.$message.error(data.message || '发送验证码失败');
        }
      } catch (error) {
        this.$message.error('邮箱已注册');
        console.log(error);
      }
    },

    startCountdown() {
      this.countdown = 180; // 设置倒计时秒数
      this.isDisabled = true; // 禁用按钮

      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(interval); // 倒计时结束
          this.isDisabled = false; // 启用按钮
        }
      }, 1000); // 每秒更新一次
    },

    // 注册的点击事件
    async registerForm () {
      this.$refs.reguserForm.validate(async (valid) => {
        if (valid) {
          try {
            // 调用接口
            const res = await registerAPI(this.reguserForm)
            this.message = res.message || '注册成功'
            this.messageClass = 'success-message' // 根据需要自定义成功消息样式
            // 跳转到登录页面
            this.$router.push('/login')
          } catch (error) {
            this.message = '注册失败，请重试'
            this.messageClass = 'error-message' // 根据需要自定义错误消息样式
          }
        } else {
          return false // 阻止默认提交行为
        }
      })
    }

    // // 注册的点击事件
    // registerForm () {
    //   // JS兜底校验
    //   this.$refs.reguserForm.validate(async valid => {
    //     if (valid) {
    //       // 通过校验
    //       console.log(this.reguserForm)
    //       // 1.调用接口
    //       const { data: res } = await registerAPI(this.reguserForm)
    //       // console.log(res)
    //       // // 2.注册失败，提示用户
    //       // if (res.code !== 0) return this.$message.error(res.message)
    //       // 3.注册成功，提示用户
    //       this.$message.success(res.message)
    //       // 4.跳转到登录页面
    //       this.$router.push('/login')
    //     } else {
    //       return false // 阻止默认提交行为（表单下面红色提示会自动出现——）
    //     }
    //   })
    // }

    

  }
}
</script>

<style scoped>
.login_box {
  display: flex;
  height: 100vh;
}

.login_box1 {

  width: 36%;
  background-color: #f8ccd8;
  background-image: url("../assets/sign-in.jpeg");
  background-size: contain;
  background-repeat: no-repeat, repeat;
  background-position: 0% 100%;
}

.login_box1 p {
  color: #fff9fa;
  font-size: 36px;

  padding-top: 80px;
  display: flex;
  justify-content: center;
}

.login_box2 {

  width: 64%;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hah {
  height: 32px;
  width: 350px;
  padding-top: 180px;

}

.hah p {
  font-size: 38px;

  font-weight: 900;
  color: rgba(0, 0, 0, 0.8);
}

.haf {
  width: 350px;
  height: 348px;
  padding-bottom: 180px;

}

.haf el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.haf p {
  font-size: 14px;
}

.input_one {
  margin-block-end: 24px;
}

.input_two {
  margin-block-end: 24px;
}

.input_password {
  margin-block-end: 24px;
}

.input_code {
  margin-block-end: 64px;
}

.no-background-btn {
  background-color: transparent !important;
  border: none !important;
  color: #409EFF;
  /* 你可以根据需要修改颜色 */
}

.btn_login {
  margin-block-end: 2px;
}

.btn_login .el-button {
  width: 350px;

}

.btn_1 {
  display: flex;
}

.btn_1_txt {
  color: #666;
}

.box_10 {
  display: flex;
}

.box_9 {
  font-size: 12px;
  color: #999;
  /* margin-top: 300px; */
  margin-right: 10px;
}

.box_8 {
  font-size: 12px;
  color: #999;
}

.box_7 {
  font-size: 12px;
}

.el-link.el-link--default {
  color: #999;
}</style>
