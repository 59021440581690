<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div ref="section">
    <div class="mainbox_one">
      <div class="mainbox_one_title">{{ menulist.name }}</div>
      <el-row :gutter="90" class="list-box">
        <VueDraggable ref="el" v-model="cardDatas" @end="updateSortOrder">
          <el-col
            class="el_row"
            :span="4"
            v-for="(cardData, index) in cardDatas"
            :key="index"
            :index="cardData.id"
          >
            <div>
              <el-link :href="cardData.url" :underline="false" target="_blank">
                <el-card
                  class="box_card"
                  shadow="hover"
                  :body-style="{ padding: '0px' }"
                >
                  <div class="card_header">
                    <el-dropdown placement="top-end">
                      <span @click.prevent>
                        <i class="el-icon-more"></i>
                      </span>

                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="editCardData(cardData)"
                          >修改</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="removeCard(cardData.id)"
                          >删除</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>

                  <el-image :src="cardData.logoImg" class="webimg">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-card>
              </el-link>

              <div class="card_name">
                <span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="cardData.content"
                    placement="bottom-start"
                  >
                    <el-link target="_blank" :href="cardData.url">
                      {{ cardData.title }}
                    </el-link>
                  </el-tooltip>
                </span>
              </div>
            </div>
          </el-col>

          <el-col :span="4" class="add-col">
            <el-button
              class="card_add"
              icon="el-icon-plus"
              @click="dialogFormVisible = true"
            ></el-button>
            <div class="card_name">
              <span @click="dialogFormVisible = true">添加</span>
            </div>
          </el-col>
        </VueDraggable>
      </el-row>
    </div>
    <!-- 添加卡片 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      width="83.333333%"
      top="0px"
      :modal="false"
      @closed="onDialogClosedFn"
    >
      <div class="dialog_box">
        <p>添加</p>

        <el-form
          :model="addCard"
          :rules="rulesAddCardRef"
          ref="addCardRef"
          label-width="158px"
          label-position="left"
          hide-required-asterisk
        >
          <el-form-item label="网站地址" prop="url">
            <el-input
              v-model="addCard.url"
              placeholder="例如: https://www.zhancun.top"
            ></el-input>
            <el-button
              type="text"
              v-if="addCard.url"
              @click="fetchData"
              :loading="isLoading"
              >AI解析网址</el-button
            >
          </el-form-item>
          <el-form-item label="网站名称" prop="title">
            <el-input v-model="addCard.title"></el-input>
          </el-form-item>
          <el-form-item label="网站描述" prop="content">
            <el-input type="textarea" v-model="addCard.content"></el-input>
          </el-form-item>
          <el-form-item label="网站图标" prop="logoImg">
            <el-card
              class="box_card"
              shadow="never"
              :body-style="{ padding: '0px' }"
            >
              <el-image
                :src="addCard.logoImg"
                alt=""
                class="cover-img"
                ref="imgRef"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>

              <input
                type="file"
                style="display: none"
                accept="image/*"
                ref="iptFileRef"
                @change="onCoverChange"
              />
            </el-card>

            <el-button type="text" @click="chooseImgFn">+ 选择图片</el-button>
          </el-form-item>
        </el-form>
        <div class="dialog_box_btn">
          <el-button type="primary" @click="submitUpload(addCard)"
            >保存</el-button
          >
          <el-button @click="onDialogClosedFn">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 修改卡片数据 -->
    <el-dialog
      title=""
      :visible.sync="dialogFormVisibleEdit"
      width="83.333333%"
      top="0px"
      :modal="false"
    >
      <div class="dialog_box">
        <p>修改</p>
        <el-form
          :model="editCard"
          ref="editCardRef"
          :rules="rulesAddCardRef"
          label-width="158px"
          label-position="left"
          hide-required-asterisk
        >
          <el-form-item label="网站地址" prop="url">
            <el-input
              v-model="editCard.url"
              @focus="showButton = true"
            ></el-input
            ><el-button
              type="text"
              v-if="showButton"
              @click="editFetchData"
              :loading="isLoading"
              >AI解析网址</el-button
            >
          </el-form-item>
          <el-form-item label="网站名称" prop="title">
            <el-input v-model="editCard.title"></el-input>
          </el-form-item>
          <el-form-item label="网站描述" prop="content">
            <el-input type="textarea" v-model="editCard.content"></el-input>
          </el-form-item>
          <el-form-item label="网站图标" prop="imageUrl">
            <el-card
              class="box_card"
              shadow="never"
              :body-style="{ padding: '0px' }"
            >
              <el-image
                :src="editCard.logoImg"
                alt=""
                class="cover-img"
                ref="imgRef"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>

              <input
                type="file"
                style="display: none"
                accept="image/*"
                ref="iptFileRef"
                @change="onEditChange"
              />
            </el-card>

            <el-button type="text" @click="chooseImgFn">+ 选择图片</el-button>
          </el-form-item>
        </el-form>
        <div class="dialog_box_btn">
          <el-button type="primary" @click="saveEditCardData()">保存</el-button>
          <el-button @click="dialogFormVisibleEdit = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getWebsiteListAPI,
  addWebsiteAPI,
  delWebsiteAPI,
  editWebsiteAPI,
  getWebsiteDataAPI,
  saveLayoutAPI,
} from "@/api";
import { VueDraggable } from "vue-draggable-plus"; //拖拽网站的库

export default {
  components: {
    VueDraggable,
  },

  props: {
    menulist: {
      type: Object,
      required: true,
    },
  }, // 从home传过来的值

  data() {
    return {
      // 添加卡片数据
      isLoading: false,
      addCard: {
        url: "",
        title: "",
        content: "",
        logoImg: "",
        logoImgUrl: null,
        categoryId: "",
      },
      cardDatas: [],
      // nextCardId: "",

      dialogFormVisible: false,
      dialogFormVisibleEdit: false,

      // 修改卡片数据

      editCard: {
        id: "",
        url: "",
        title: "",
        content: "",
        logoImgUrl: "", // 用于存储用户上传的文件
        logoImg: "",
      },

      showButton: false, // 解析按钮是否显示

      rulesAddCardRef: {
        url: [
          { required: true, message: "请输入网址", trigger: "blur" },
          { type: "url", message: "请输入正确的网址", trigger: "blur" },
        ],
        title: [{ validator: this.validLength, trigger: "blur" }],
        content: [{ max: 50, message: "长度最多50个汉字", trigger: "blur" }],
      },
    };
  },

  created() {
    // 获取网站
    this.initWebsiteListFn();
  },

  methods: {
    scrollTo() {
      this.$refs.section.scrollIntoView({ behavior: "smooth" });
    },

    // 校验字符长度的方法
    validLength(rule, value, callback) {
      function getStrLength(str) {
        return str.replace(/[^x00-xff]/g, "xxx").length;
      }
      if (!value) {
        callback(new Error("输入不能为空"));
      } else if (getStrLength(value) < 1) {
        callback(new Error("不能少于 1 个字符"));
      } else if (getStrLength(value) > 24) {
        callback(new Error("中文1-8个汉字 (英文1-24)"));
      } else {
        callback();
      }
    },

    // 获取网站

    // async initWebsiteListFn () {
    //   const categoryId = this.menulist.id
    //   const res = await getWebsiteListAPI(categoryId)
    //   this.cardDatas = res.data.data.map(card => {
    //     const serverAddress = this.$backurl
    //     const imagePath = card.logoImg
    //     let logoImg = imagePath
    //     // 检查 imagePath 是否已经是一个完整的 URL
    //     if (!/^https?:\/\//i.test(imagePath)) {
    //       // 如果不是，就把它当作文件路径来处理
    //       logoImg = serverAddress + imagePath
    //     }
    //     return {
    //       ...card,
    //       logoImg
    //     }
    //   })
    //   // console.log(res)
    // },

    // async initWebsiteListFn() {
    //   try {
    //     const categoryId = this.menulist.id;
    //     const res = await getWebsiteListAPI(categoryId);

    //     if (res.data && res.data.data) {
    //       this.cardDatas = res.data.data.map((card) => {
    //         const serverAddress = this.$backurl;
    //         let logoImg = card.logoImg;

    //         // 检查 logoImg 是否已经是一个完整的 URL
    //         if (!/^https?:\/\//i.test(logoImg)) {
    //           // 如果不是，就拼接 serverAddress 和 logoImg
    //           logoImg = `${serverAddress.replace(/\/$/, "")}/${logoImg.replace(
    //             /^\//,
    //             ""
    //           )}`;
    //         }

    //         return {
    //           ...card,
    //           logoImg,
    //         };
    //       });

    //       // console.log('Card data:', this.cardDatas); // 打印数据以调试
    //     } else {
    //       console.error("Error: Unexpected API response", res);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // },
    async initWebsiteListFn() {
      try {
        const categoryId = this.menulist.id;
        const res = await getWebsiteListAPI(categoryId);

        if (res.data && res.data.data) {
          this.cardDatas = res.data.data.map((card) => {
            const serverAddress = this.$backurl;
            let logoImg = card.logoImg;

            // 检查 logoImg 是否存在且是字符串
            if (logoImg && typeof logoImg === "string") {
              // 检查 logoImg 是否已经是一个完整的 URL
              if (!/^https?:\/\//i.test(logoImg)) {
                // 如果不是，就拼接 serverAddress 和 logoImg
                logoImg = `${serverAddress.replace(
                  /\/$/,
                  ""
                )}/${logoImg.replace(/^\//, "")}`;
              }
            }

            // 如果 logoImg 为空，则保持为空，不做处理
            return {
              ...card,
              logoImg,
            };
          });

          // console.log('Card data:', this.cardDatas); // 打印数据以调试
        } else {
          console.error("Error: Unexpected API response", res);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    //解析网站数据的方法
    async fetchData() {
      try {
        this.isLoading = true;
        const url = this.addCard.url;
        const res = await getWebsiteDataAPI(url);
        this.addCard = res.data;
        console.log(res);
      } catch (error) {
        console.error(error);
        // 如果发生错误，你可以在这里处理，例如显示一个错误消息
        this.$message.error("获取网站数据失败 请自己添加");
      } finally {
        this.isLoading = false;
      }
    },

    // 选择图片的弹窗事件方法
    chooseImgFn() {
      this.$refs.iptFileRef.click();
    },

    // 选择了上传的图片
    onCoverChange(e) {
      // 获取到用户选择的封面
      const file = e.target.files[0];
      if (file) {
        // 检查文件大小
        const maxSize = 50 * 1024; // 50KB
        if (file.size > maxSize) {
          this.$message.error("图片大小超过50kb!");
          return;
        }

        // 创建一个FileReader来读取文件
        const reader = new FileReader();
        reader.onload = (e) => {
          // 创建一个Image来获取图片尺寸
          const img = new Image();
          img.onload = () => {
            // 检查图片尺寸
            const maxDimension = 500; // 500px
            if (img.width > maxDimension || img.height > maxDimension) {
              this.$message.error("图片尺寸超过500px!");
              return;
            }

            // 如果文件大小和尺寸都符合要求，那么可以进行上传操作
            this.addCard.logoImgUrl = file;
            this.addCard.logoImg = URL.createObjectURL(file);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    //添加网站的方法
    submitUpload() {
      this.$refs.addCardRef.validate(async (valid) => {
        if (!valid) return;

        const categoryId = this.menulist.id;

        const formData = new FormData();
        formData.append("title", this.addCard.title);
        formData.append("content", this.addCard.content);
        formData.append("url", this.addCard.url);

        // 判断logoImgUrl是文件还是链接
        if (this.addCard.logoImgUrl instanceof File) {
          // 如果是文件，添加到表单
          formData.append("logoImg", this.addCard.logoImgUrl);
        } else if (typeof this.addCard.logoImg === "string") {
          // 如果是链接，添加到表单
          formData.append("logoImg", this.addCard.logoImg);
        }
        // 打印 FormData 内容，检查是否包含文件
        // for (const pair of formData.entries()) {
        //   console.log(`${pair[0]}: ${pair[1]}`);
        // }
        const { data: res } = await addWebsiteAPI({ formData, categoryId });
        // console.log(res);
        this.initWebsiteListFn();
        this.dialogFormVisible = false;
      });
    },

    
    // 对话框完全关闭之后的处理函数
    onDialogClosedFn() {
      // 清空关键数据
      this.$refs.addCardRef.resetFields();
      // 因为这2个变量对应的标签不是表单绑定的, 所以需要单独控制

      // this.$refs.imgRef.setAttribute('src', null)
      this.dialogFormVisible = false;
    },

    // 显示修改的卡片数据
    async editCardData(cardData, index) {
      this.editCard.id = cardData.id;
      this.editCard.url = cardData.url;
      this.editCard.title = cardData.title;
      this.editCard.content = cardData.content;
      this.editCard.logoImg = cardData.logoImg;
      this.dialogFormVisibleEdit = true;

      console.log(this.editCard);
    },

    // onEditChange (e) {
    //   // 获取到用户选择的封面
    //   const file = e.target.files[0]
    //   if (file) {
    //     // 检查文件大小
    //     const maxSize = 50 * 1024 // 50KB
    //     if (file.size > maxSize) {
    //       this.$message.error('图片大小超过50kb!')
    //       return
    //     }

    //     // 创建一个FileReader来读取文件
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //       // 创建一个Image来获取图片尺寸
    //       const img = new Image()
    //       img.onload = () => {
    //         // 检查图片尺寸
    //         const maxDimension = 500 // 500px
    //         if (img.width > maxDimension || img.height > maxDimension) {
    //           this.$message.error('图片尺寸超过500px!')
    //           return
    //         }

    //         // 如果文件大小和尺寸都符合要求，那么可以进行上传操作
    //         this.editCard.logoImgUrl = file
    //         this.editCard.logoImg = URL.createObjectURL(file)
    //       }
    //       img.src = e.target.result
    //     }
    //     reader.readAsDataURL(file)
    //   }
    // },

    onEditChange(e) {
      // 获取到用户选择的封面
      const file = e.target.files[0];
      if (file) {
        // 检查文件大小
        const maxSize = 50 * 1024; // 50KB
        if (file.size > maxSize) {
          this.$message.error("图片大小超过50kb!");
          return;
        }

        // 创建一个FileReader来读取文件
        const reader = new FileReader();
        reader.onload = (e) => {
          // 创建一个Image来获取图片尺寸
          const img = new Image();
          img.onload = () => {
            // 检查图片尺寸
            const maxDimension = 500; // 500px
            if (img.width > maxDimension || img.height > maxDimension) {
              this.$message.error("图片尺寸超过500px!");
              return;
            }

            // 如果文件大小和尺寸都符合要求，那么可以进行上传操作
            this.editCard.logoImgUrl = file;
            this.editCard.logoImg = URL.createObjectURL(file);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.editCard.logoImgUrl = this.editCard.logoImg;
      }
    },

    // 编辑输入网址获取该网站标题简介和logo数据
    async editFetchData() {
      try {
        this.isLoading = true;
        const url = this.editCard.url;
        const res = await getWebsiteDataAPI(url);

        // 只更新特定的字段
        this.editCard.title = res.data.title;
        this.editCard.content = res.data.content;
        this.editCard.logoImg = res.data.logoImg;
      } catch (error) {
        console.error(error);
        // 如果发生错误，你可以在这里处理，例如显示一个错误消息
        this.$message.error("获取网站数据失败 请自己添加");
      } finally {
        this.isLoading = false;
      }
    },

    // 保存修改的数据
    saveEditCardData() {
      this.$refs.editCardRef.validate(async (valid) => {
        if (!valid) return;
        // 通过校验
        // console.log(this.editCard)
        const formData = new FormData();
        formData.append("id", this.editCard.id);
        formData.append("title", this.editCard.title);
        formData.append("content", this.editCard.content);
        formData.append("url", this.editCard.url);

        // formData.append('logoImg', this.editCard.logoImgUrl)

        // 判断logoImgUrl是文件还是链接
        if (this.editCard.logoImgUrl instanceof File) {
          // 如果是文件，添加到表单
          formData.append("logoImg", this.editCard.logoImgUrl);
        } else if (typeof this.editCard.logoImg === "string") {
          // 如果是链接，添加到表单
          formData.append("logoImg", this.editCard.logoImg);
        }

        // //查看formdata发送的数据
        // for (const pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1])
        // }
        const { data: res } = await editWebsiteAPI(formData);
        // console.log(res)
        if (res.status !== 0) return this.$message.error(res.message);
        // this.$message.success(res.message);
        // 重新请求列表数据
        this.initWebsiteListFn();
        this.showButton = false;
        this.dialogFormVisibleEdit = false;
      });
    },

    // vue保存编辑的数据
    // saveEditCardData () {
    //   Vue.set(this.cardDatas, this.nextCardId, this.editCard)
    //   this.dialogFormVisibleEdit = false
    // },

    cancelEdit() {
      this.showButton = false;
      this.dialogFormVisibleEdit = false;
      this.addCard = {};
    },

    // 删除卡片
    // removeCard (id) {
    //   this.cardDatas.splice(this.cardDatas.indexOf(id), 1)
    //   // this.cardDatas.splice(i, 1);
    // },

    async removeCard(id) {
      // console.log(id)
      // 执行删除的操作
      // this.cardDatas.splice(this.cardDatas.indexOf(id), 1)
      const res = await delWebsiteAPI(id);
      console.log(res);
      // if (res.code !== 0) return this.$message.error('删除失败!')
      // this.$message.success('删除成功!')

      // 刷新列表数据
      this.initWebsiteListFn();
    },

    //拖拽网站卡片位置  2024.9.27 下午 【。】
    async updateSortOrder() {
      const updates = this.cardDatas.map((item, index) => ({
        id: item.id,
        sort_order: index + 1,
        category_id: item.category_id,
      }));

      // console.log(updates); // 打印更新数据以确认格式

      try {
        await saveLayoutAPI(updates); // 调用 API
        // this.$message.success('排序更新成功！'); // 提示用户
      } catch (error) {
        this.$message.error("更新排序失败！"); // 错误处理
        console.error("更新失败:", error); // 打印错误以便调试
      }
    },
  },
};
</script>

<style scoped>
.mainbox_one {
  height: auto;
  /* margin-block-end: 42px; */
  padding-top: 16px;
}

.mainbox_one_title {
  width: 170px;
  height: 33px;
  padding-block-end: 18px;
  font-size: 24px;
  font-weight: 600;
  color: #1d1d1f;
  line-height: 33px;

  white-space: nowrap;
  /* 防止文本换行 */
  overflow: hidden;
  /* 隐藏超出容器的文本 */
  text-overflow: ellipsis;
  /* 将超出的文本显示为省略号 */
}

.el_row {
  padding: 0 45px 24px 45px;
}

.box_card {
  height: 90px;
  width: 90px;
  border-radius: 12px;
  border: 1px solid #eeeeee;
  background-color: rgb(255, 255, 255);
}

/* .el-card {

    height: 90px;
    width: 90px;
    border-radius: 12px;
    border: 1px solid #eeeeee;
    background-color: rgb(255, 255, 255);

} */

.webimg {
  width: 32px;
  height: 32px;
  display: block;
  margin-left: 29px;
  margin-right: 29px;
  padding: 29px 0 29px 0;
}

.card_header {
  float: right;
  padding: 4px 4px;
  opacity: 0;
  /* background-color: aqua; */
}

.el-card:hover .card_header {
  opacity: 1;
}

.el-icon-more {
  color: #666;
  transform: rotate(90deg);
}

/* 添加按钮 */

.add-col {
  padding-bottom: 24px;
}

.card_add {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 12px;
  /* box-sizing: border-box; */
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin: 0px 0px 0px 0px;
  padding: 0;
  font-size: 28px;
  color: #8c939d;
}

.card_name {
  text-align: center;
  width: 90px;
  height: 20px;
  font-size: 14px;

  font-weight: 400;
  color: #333;
  line-height: 20px;
  margin-top: 8px;
  margin-right: 0;
}

.el-link.el-link--default {
  color: #1d1d1f;
}

.cover-img {
  width: 32px;
  height: 32px;
  display: block;
  margin-left: 29px;
  margin-right: 29px;
  padding: 29px 0 29px 0;
  /* object-fit: cover; */
}

.el-icon-picture-outline {
  font-size: 32px;
  color: #eeeeee;
}
</style>
