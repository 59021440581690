// // 项目核心请求方法的模块文件

// import axios from 'axios'

// // 开发环境
// axios.defaults.baseURL = 'http://127.0.0.1:3000'
// // 生产环境
// // axios.defaults.baseURL = 'http://106.15.76.203:8989'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// export default axios

// @/utils/request.js
import axios from 'axios';
import config from './config';
import router from '@/router';
import qs from 'qs';

// 创建 axios 实例
// const request = axios.create({
//   baseURL: 'http://127.0.0.1:3000', // 设置基本 URL 开发环境
//   // baseURL: 'http://106.15.76.203:8989',  // 生产环境
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded'
//   }
// });

const request = axios.create({
  baseURL: config.baseURL,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
    // 'Content-Type': 'application/json'
  }
});


// 请求拦截器
request.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截器
request.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    // 如果响应状态码是 401，则表示令牌过期或无效
    localStorage.removeItem('token'); // 清除无效令牌
    router.push('/login'); // 重定向到登录页
  }
  return Promise.reject(error);
});


export default request;

