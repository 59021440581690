import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Reguser from '../views/Reguser.vue'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/reguser',
    name: 'Reguser',
    component: Reguser
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true } // 需要身份验证的路由


  },

  {
    path: '/',
    redirect: '/login'
  }

]

const router = new VueRouter({
  routes

})

// 无需验证token的页面
const whiteList = ['/login', '/reguser']

router.beforeEach(async (to, from, next) => {
  const token = store.state.token
  if (token) {
    if (!store.state.userInfo.email) {
      // 有token但是没有用户信息, 才去请求用户信息保存到vuex里
      // 调用actions里方法请求数据
      await store.dispatch('getUserInfoActions')
    }
    next() // 路由放行
  } else {
    // 如果无token
    // 如果去的是白名单页面, 则放行
    if (whiteList.includes(to.path)) {
      next()
    } else {
      // 如果其他页面请强制拦截并跳转到登录页面
      next('/login')
    }
  }
})

// // 无需验证token的页面
// const whiteList = ['/login', '/reguser']

// router.beforeEach(async (to, from, next) => {
//   const token = store.state.token

//   if (token) {
//     if (store.getters.isTokenExpired) { // 使用getters调用isTokenExpired函数
//       // 如果token过期，跳转到登录页面
//       store.dispatch('clearUserInfo') // 清除用户信息和token
//       next('/login')
//     } else if (!store.state.userInfo.useremail) {
//       // 有token但是没有用户信息, 才去请求用户信息保存到vuex里
//       // 调用actions里方法请求数据
//       await store.dispatch('getUserInfoActions')
//       next() // 路由放行
//     }
//   } else {
//     // 如果无token
//     // 如果去的是白名单页面, 则放行
//     if (whiteList.includes(to.path)) {
//       next()
//     } else {
//       // 如果其他页面请强制拦截并跳转到登录页面
//       next('/login')
//     }
//   }
// })

export default router
