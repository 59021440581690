const config = {
    development: {
      baseURL: 'http://127.0.0.1:3000'
    },
    production: {
      baseURL: 'https://api.zhancun.top'
    }
  };
  
  const env = process.env.NODE_ENV || 'development'; // 默认为开发环境

  
  
  export default config[env];
  