import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import { getUserInfoAPI } from '@/api'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // 1. 用来存储登录成功之后，得到的 token
    token: '',
    userId: '',
    username: '',
    userInfo: {} // 定义用户信息对象

  },
  
  mutations: {
    // 2. 更新 token 的 mutation 函数
    updateToken (state, payload) {
      state.token = payload.token
      state.userId = payload.userId
      state.username = payload.username
    },

    // 更新用户的信息
    updateUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },

    // 清除用户信息和 token
    clearUser(state) {
      state.token = '';
      state.userId = '';
      state.username = '';
      state.userInfo = {};
    }

  },

  actions: {

    // 定义初始化用户基本信息的 action 函数
    async getUserInfoActions (store) {
      const { data: res } = await getUserInfoAPI()
      if (res.status === 0) {
        store.commit('updateUserInfo', res.data)
      }
    },

    

    // 用户登出
    logout({ commit }) {
      commit('clearUser');
      router.push('/login'); // 跳转到登录页面
    }

  },

  getters: {
    id: state => state.userInfo.id,

    username: state => state.userInfo.username // 用户名

  },

  modules: {
  },
  // 配置为 vuex 的插件
  plugins: [createPersistedState()]
})

export default store
