<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="user_box">
      <h1 class="title_stly">用户管理</h1>
      <el-popover placement="top" width="160" v-model="visible">
        <p>说退出就退出！</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="visible = false">取消</el-button>
          <el-button type="primary" size="mini" @click="logoutFn">确定</el-button>
        </div>
        <el-button slot="reference">退出登录</el-button>
      </el-popover>

    </div>

    <el-table :data="tableData" style="width: 95%">

      <el-table-column prop="name">
      </el-table-column>
      <el-table-column align="right">
        <template>
          <el-button size="mini" >编辑</el-button>

        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
export default {
  data () {
    return {

      visible: false,
      tableData: []

    }
  },
  methods: {
    logoutFn () {
      // 清除 vuex,
      this.$store.commit('updateToken', '')
      this.$store.commit('updateUserInfo', {})
      // 强制跳转
      this.$router.push('/login')
    }

  }
}
</script>

<style scoped>
.user_box {
  display: flex;
  justify-content: space-between;
  height: 60px;
  margin-top: 30px;
}

.title_stly{
  color: #1d1d1f;
}

.user_box .el-button {
  height: 40px;
  margin-right: 70px;
}

.box_line {
  height: 1px;
  width: 1040px;
  background-color: rgb(194, 194, 194);
}
</style>
