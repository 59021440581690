<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-carousel height="200px" direction="vertical">
          <el-carousel-item v-for="imgData in imgDatas" :key="imgData.id">
            <el-link :href="imgData.url" target="_blank">
              <el-image class="topimg" :src="imgData.banner"></el-image>
            </el-link>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { getBannerAPI } from '@/api'
export default {
  data () {
    return {
      imgDatas: []
    }
  },
  created () {
    // 获取banner
    this.getBannerFn()
  },
  methods: {

    // 获取banner
    // async getBannerFn () {
    //   const res = await getBannerAPI()
    //   this.imgDatas = res.data.data.map(card => {
    //     const serverAddress = this.$backurl
    //     const imagePath = card.banner
    //     const banner = serverAddress + imagePath
    //     return {
    //       ...card,
    //       banner
    //     }
    //   })

    //   // console.log(this.imgDatas)
    // }

    async getBannerFn () {
      const res = await getBannerAPI()
      
      if (res.data && res.data.data) {
        this.imgDatas = res.data.data.map(card => {
          const serverAddress = this.$backurl
          const imagePath = card.banner
          // const banner = serverAddress + imagePath
          const banner = `${serverAddress.replace(/\/$/, '')}/${imagePath.replace(/^\//, '')}`; // 拼接完整 URL
          // console.log('Full image URL:', banner); // 打印完整 URL 进行验证
          return {
            ...card,
            banner
          }
        })
      } else {
        console.error('Error: Unexpected API response', res)
      }
    }

  }
}

</script>

<style scoped>
.el-carousel {
  width: 990px;
  border-radius: 12px;
}

.topimg {

  height: 200px;
  width: 990px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #346bb8;
  width: 990px;

  border-radius: 12px;

}

.el-carousel__item:nth-child(2n+1) {
  background-color: #e6e2d3;
  width: 990px;

  border-radius: 12px;
}
</style>
