<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <el-container>
      <el-aside width="240px">
        <img class="aside-logo" src="../assets/logo.svg" alt="" />

        <div>
          <el-button type="text" class="aside_box1" @click="welcomeStatus = true">站友交流</el-button><br />

          <el-link class="aside_boxx" href="https://zhancun.top" target="_blank" :underline="false">站村</el-link>
          <div class="aside_box2">
            <div class="box2_name">{{ username + "的站村" }}</div>

            <el-link :underline="false" icon="el-icon-right" @click="userinfoStatus = true" class="box2_button"></el-link>
          </div>

          <el-menu @item-click="handleClick">
            <el-menu-item v-for="menulist in menuLists" :key="menulist.id" :index="menulist.id.toString()"
              class="el-menu-item_box">
              <el-input v-if="inputVisibleTwo && menulist.id === editMenuList.id" v-model="menulist.name"
                @keyup.enter.native="editMenuListFn" @blur="editMenuListFn" :placeholder="errorMessage" ref="inputRef"
                autofocus></el-input>

              <div v-else class="menu-item-content">
                <!-- 标题 -->
                <div class="menu-item-title">
                  {{ menulist.name }}
                </div>

                <div class="menu-item-icon">
                  <!-- 编辑图标 -->

                  <i class="el-icon-edit edit-icon" @click="showInputTwo(menulist)"></i>

                  <el-popconfirm placement="right" title="确定删除全部内容吗？" @confirm="removeMenulist(menulist.id)">
                    <i class="el-icon-circle-close close-icon" slot="reference"></i>
                  </el-popconfirm>
                </div>
              </div>
            </el-menu-item>
          </el-menu>

          <div>
            <div v-if="inputVisible" :key="1">
              <el-form :model="addMenuList" :rules="rules" ref="addRef">
                <el-form-item prop="name">
                  <el-input v-model="addMenuList.name" @keyup.enter.native="addMenulistFn(addMenuList)" autofocus
                    placeholder="请输入1-8个汉字(英文1-24)"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div v-else :key="2">
              <el-button class="addCun" type="text" @click="showInput">创建新的村子</el-button>
            </div>
          </div>
        </div>
      </el-aside>
      <el-main>
        <div>
          <el-backtop target=".el-main"></el-backtop>

          <Topimage />
          <Designone />

          <Designtwo v-for="menulist in menuLists" :key="menulist.id" :menulist="menulist" :ref="`${menulist.id}`" />
        </div>

        <el-dialog :visible.sync="welcomeStatus" width="83.333333%" top="0px" :modal="false">
          <div class="welcome_box">
            <Welcome />
          </div>
        </el-dialog>

        <el-dialog :visible.sync="userinfoStatus" width="83.333333%" top="0px" :modal="false">
          <div class="welcome_box">
            <Userinfo />
          </div>
        </el-dialog>

        <!-- <router-view></router-view> -->
      </el-main>
    </el-container>
  </div>
</template>

<script>
// import Addaside from "@/components/ Addaside.vue";
import Topimage from "@/components/Topimage.vue";
import Designone from "@/components/designlist/Designone.vue";
import Designtwo from "@/components/designlist/Designtwo.vue";
import Welcome from "@/components/Welcome.vue";
import Userinfo from "@/components/Userinfo.vue";

import {
  getCategoryListAPI,
  addCategoryAPI,
  updateCategoryAPI,
  delCategoryAPI,
} from "@/api";
// import { mapGetters } from 'vuex'

export default {
  components: {
    // Addaside,
    Topimage,
    Designone,
    Designtwo,
    Welcome,
    Userinfo,
  },
  data() {
    return {
      visible: false,

      addMenuList: {
        // name: ''
      }, // 添加分类
      menuLists: [], // 分类数据
      addMenuId: null,

      editMenuListId: null,
      editMenuList: { name: "" },
      errorMessage: "请输入内容", // 用于存储验证错误消息

      inputVisible: false, // 输入框是否显示
      inputVisibleTwo: false, // 编辑输入框是否显示

      buttonStatus: false, // 图标是否显示

      welcomeStatus: false, // 欢迎页是否显示

      userinfoStatus: false, // 用户设置页是否显示

      // id: null
      rules: {
        name: [
          { validator: this.validLength, trigger: "blur" },
          // { min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur' }
        ],
      },
    };
  },
  // props: {
  //   userId: {
  //     type: Number,
  //     required: true
  //   }
  // },
  // mounted () {
  //   // 调用封装好的函数获取数据
  //   getCategoryListAPI(this.userId)
  //     .then(res => {
  //       this.cateData = res.data
  //     })
  // },

  // created () {
  //   // 获取用户名
  //   this.initNameFn()
  // },
  // computed: {
  //   ...mapGetters(['username', 'id'])
  // },
  computed: {
    username() {
      return this.$store.state.username;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    // 获取文章分类
    this.initListFn();
  },

  methods: {
    handleClick(menuItem) {
      // console.log(id)
      // console.log(this.$refs[id])

      this.$nextTick(() => {
        const ref = this.$refs[menuItem.index];
        if (ref && ref[0]) {
          ref[0].scrollTo();
        }
      });
    },

    
    // 校验字符长度的方法
    validLength(rule, value, callback) {
      function getStrLength(str) {
        return str.replace(/[^x00-xff]/g, "xxx").length;
      }
      if (!value) {
        callback(new Error("输入不能为空"));
      } else if (getStrLength(value) < 1) {
        callback(new Error("不能少于 1 个字符"));
      } else if (getStrLength(value) > 24) {
        callback(new Error("不能超过 24 个字符"));
      } else {
        callback();
      }
    },
    // 获取文章分类
    async initListFn() {
      const res = await getCategoryListAPI(this.userId);
      this.menuLists = res.data.data;
      // console.log(res)
    },

    // 显示添加输入框
    showInput() {
      this.inputVisible = true;
    },

    // 添加分类 --让对话框消失，调用保存分类接口
    addMenulistFn() {
      this.$refs.addRef.validate(async (valid) => {
        if (valid) {
          // 通过校验
          // console.log(this.addMenuList.name)
          const { data: res } = await addCategoryAPI({
            userId: this.userId,
            name: this.addMenuList.name,
          });
          if (res.status !== 0) return this.$message.error(res.message);
          // this.$message.success(res.message)
          console.log(res);
          // 重新请求列表数据
          this.initListFn();
          this.addMenuList = {};
        } else {
          
          return false;
        }
      });
      this.addMenuList = {};
      this.inputVisible = false;
    },

    // 修改分类按钮迪点击事件

    showInputTwo(menulist) {
      // console.log(menulist)
      this.inputVisibleTwo = true;
      this.editMenuList = menulist;
    },
    // // 编辑分类

    async editMenuListFn() {
      // 基本验证逻辑
      if (
        typeof this.editMenuList.name !== "string" ||
        !this.editMenuList.name.trim()
      ) {
        this.errorMessage = "分类名称不能为空";
        return;
      }

      try {
        // 清除之前的错误消息
        this.errorMessage = "";

        // 调用 API 更新分类
        const { data: res } = await updateCategoryAPI(this.editMenuList);

        if (res.status !== 0) {
          this.$message.error(res.message);
          return;
        }

        // 成功后显示消息
        // this.$message.success("分类更新成功");

        // 重新请求列表数据
        await this.initListFn();

        // 清理编辑数据（可选）
        this.editMenuList = { name: "" };
      } catch (error) {
        // 捕获和处理错误
        console.error("编辑分类时发生错误:", error);
        this.$message.error("编辑分类时发生错误，请重试");
      } finally {
        // 确保对话框在无论成功还是失败后都能关闭
        this.inputVisibleTwo = false;
      }
    },

    // 删除-分类
    async removeMenulist(id) {
      // console.log(id)
      this.menuLists = this.menuLists.filter((menulist) => menulist.id !== id);

      const res = await delCategoryAPI(id);
      // this.$message.success(res.message)
      console.log(res);
    },

    // 添加左侧菜单栏
    // addMenulist () {
    //   const value = this.addMenuList && this.addMenuList.trim()
    //   if (!value) {
    //     this.inputVisible = false
    //     return
    //   }

    //   this.menuLists.push({
    //     id: this.addMenuId++,
    //     title: value
    //   })
    //   this.inputVisible = false
    //   this.addMenuList = ''
    // },
    // 删除左侧菜单栏
    // removeMenulist (index) {
    //   this.menuLists.splice(this.menuLists.indexOf(index), 1)
    // },
    // 编辑左侧菜单栏
    // editMenuListFn(menulist) {

    //   this.beforeEditCache = menulist.title
    //   this.editedMenulist = menulist
    // },

    // 隐藏输入框
    // hideInputTwo () {
    //   this.inputVisibleTwo = false
    // },

    // 显示列表删除，编辑按钮
    // showButton (id) {
    //   this.buttonStatus = true
    //   this.addMenuId = id
    // },

    // 隐藏列表删除，编辑按钮
    // hideButton () {
    //   this.buttonStatus = false
    // }
  },

  mounted() {
    // 使用原生方式添加事件监听器
    window.addEventListener('touchmove', this.handleClick, { passive: true });
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('touchmove', this.handleClick);
  },
};
</script>

<style scoped>
.el-aside {
  background-color: #ffffff;
  /* color: rgb(58, 58, 58); */
  height: 100vh;
  border-right: 1px solid #e7e7e7;
}

.el-main {
  background-color: #ffffff;
  padding: 24px 154px 400px 56px;
  height: 100vh;
  overflow-y: auto;
}

/* 侧边栏样式 */
.aside-logo {
  width: 148px;
  height: 40px;
  padding: 20px 52px 20px 40px;

  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
  /* 将 logo 的 z-index 值设置为 1 */
}

/* a {
  text-decoration: none;
  color: #444;
}

a:hover {
  color: #ff4d90;
}

a.router-link-active {
  color: #ff4d90;
} */

.aside_box1 {
  margin-left: 40px;
  margin-top: 10px;
  padding: 0px;
  color: #1d1d1f;
  font-size: 14px;
  font-weight: bold;
}

.aside_boxx {
  margin-left: 40px;
  margin-top: 14px;
  padding: 0px;
  color: #1d1d1f;
  font-size: 14px;
  font-weight: bold;
}

.el-link.el-link--default {
  color: #1d1d1f;
}

.aside_box2 {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 24px;
  padding-top: 16px;
  padding-left: 40px;
}

.aside_box2 .box2_name {
  font-size: 12px;
  font-weight: 500;
  color: #a8a8a8;
  line-height: 24px;
}

.aside_box2 .box2_button {
  font-size: 18px;
  color: #999;
  margin-right: 10px;
}

.el-menu-item_box {
  padding-left: 40px;
  padding-right: 5px;
  /* display: flex;
  align-items: center; */
  height: 48px;
  line-height: 48px;
  /* Optional: Add border or background if needed */
}

.menu-item-content {
  height: 48px;
  display: flex;
  align-items: center;
  /* Align items vertically centered */
  justify-content: space-between;
  /* Space out items horizontally */
  width: 100%;
  /* Full width of the menu item */
}

.menu-item-title {
  height: 48px;
  margin-left: 20px;

  width: 120px;
  /* 容器的宽度 */
  white-space: nowrap;
  /* 防止文本换行 */
  overflow: hidden;
  /* 隐藏超出容器的文本 */
  text-overflow: ellipsis;
  /* 将超出的文本显示为省略号 */
}

.menu-item-icon {
  height: 48px;
  display: none;
  /* display: flex;
  align-items: center; */
}

.edit-icon {
  margin-right: 10px;
}

.close-icon {
  margin-right: 10px;
}

.el-menu-item:hover .menu-item-icon {
  display: block;
}

/* .el-menu {
  border: 0px;
} */

/* .el-menu-item {
  
  height: 48px;
  line-height: 42px;
  padding-right: 40px;
  padding-left: 10px;
} */
/* .el-menu-item_input {
  display: flex;
  align-items: center;
  
} */



.el-menu-item_box {
  display: flex;
  justify-content: space-between;
}

/* .el-menu-item_box /deep/ .el-input__inner {
  border: none;
  background-color: transparent;
  padding: 0;
} */



.addCun {
  margin-left: 40px;
}



.welcome_box {
  margin-left: 56px;
}

/* .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
} */
</style>
